<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div style="background: #DBE7F2" class="p-2">
            <div>
                <h4>PO Details</h4>
                <h6>PO No: {{ purchase_order.po_number }}</h6>
                <h6>PO Date: {{ purchase_order.po_date }}</h6>
                <h6>Supplier Name: {{ purchase_order.contact_profile?.full_name }}</h6>
            </div>
        </div>
        <ListTable
                :is_edit="true"
                :list="ledgerGeneralList"
                :grn_numbers="getGrnNumbers"
                @selectGrnNumber="selectGrnNumber"
        />
    </div>
  <!--    GRN list-->
    <GrnListTable v-if="Object.keys(grnMasterLists).length !== 0"
                  :index="0"
                  :is_edit="true"
                  :grn_master_list="grnMasterLists"
                  :account_payable="accountPayable"
                  @storeLandedCostingData="updateLandedCostingData"
    />

    <GlobalLoader/>
</template>

<script setup>
import ListTable from '@/components/molecule/scm/landed-costing/LandedCostingAddTable.vue'
import GrnListTable from '@/components/molecule/scm/landed-costing/GrnListTable.vue'
import {computed, inject, onMounted, ref} from 'vue';
import {useRoute, useRouter} from "vue-router";
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleLandedCosting from "@/services/modules/scm/landed-costing";
import handlePurchase from "@/services/modules/purchase";

const router = useRouter()
const route = useRoute()

const showError = inject('showError');
const showSuccess = inject('showSuccess');

const loading = ref(false);

const {fetchSinglePO} = handleRequisitions();
const {fetchLGLandedCosting, fetchSingleLandedCosting, updateLandedCosting} = handleLandedCosting();
const {fetchAccountPayable} = handlePurchase()
let ledgerGeneralList = ref([]);
let landedCosting = ref({});
let grnMasterLists = ref({});
let purchase_order = ref({});
let accountPayable = ref([])
let companyId = computed(() => route.params.companyId);
const loader = ref(false);
let prefix = ref('')
let serial = ref('')

// on select grn number then push and remove lg amounts
const selectGrnNumber = (lg_id, amount, account_head_id, e) => {
    //Removed already exits ledger generals id and amount

    const index = grnMasterLists.value.amounts.findIndex(obj => obj.id === lg_id);
    if (index !== -1) {
        grnMasterLists.value.amounts.splice(index, 1);
    }

    if (e.target.value != "") {
        const getLedgerGeneral = grnMasterLists.value.amounts.find(amount => amount.id == lg_id)
        if (getLedgerGeneral == undefined) {
            grnMasterLists.value.amounts.push({id: lg_id, amount, account_head_id})
        }
    }
}

const getGrnNumbers = computed(() => {
    return [{
        id: grnMasterLists.value.id,
        grn_number: grnMasterLists.value.grn_number,
        ledger_generals: grnMasterLists.value.ledger_generals
    }]
})

function getCompanyQuery() {
    return `?company_id=${companyId.value}`;
}

function getQuery() {
    let query = getCompanyQuery()
    query += '&txn_no=' + purchase_order.value.po_number
    return query
}

async function getLGLandedCostingList() {
    try {
        const res = await fetchLGLandedCosting(getQuery());
        if (res.status) {
            ledgerGeneralList.value = res.data;
        } else {
            ledgerGeneralList.value = []
        }
    } catch (err) {
        if (err.response) {
            showError(err.response.message)
        }
    }
}

async function getGrnLandedCosting(id) {
    try {
        const res = await fetchSingleLandedCosting(id, getQuery());
        if (res.status) {
            purchase_order.value = res.data.purchase_order_master
            grnMasterLists.value = res.data.grn_master;
            landedCosting.value = res.data;
        } else {
            grnMasterLists.value = landedCosting.value = {}
        }
        await getLGLandedCostingList();
    } catch (err) {
        if (err.response) {
            showError(err.response.message)
        }
    }
}

async function getPurchaseOrder(id) {
    try {
        const res = await fetchSinglePO(id, getCompanyQuery());
        if (res.status) {
            purchase_order.value = res.data;
        }
    } catch (err) {
        if (err.response) {
            showError(err.response.message)
        }
    }
}

async function updateLandedCostingData(grnMaster, totalLGAmount) {
    try {
        grnMaster.total_lg_amount = totalLGAmount
        let res = await updateLandedCosting(landedCosting.value.id, grnMaster, getCompanyQuery());
        if (res.status) {
            showSuccess(res.message)
            navigateToListPage();
        }
        if (!res.status) {
            showError(res.message)
        }
    } catch (err) {
        if (err.response) {
            showError(err.response.message)
        }
    }
}

function navigateToListPage() {
    router.push({name: 'landed-costing-list', params: route.params, query: route.query})
}
async function getAccountPayable() {
    try {
        const res = await fetchAccountPayable(getCompanyQuery());
        if (res.status) {
            accountPayable.value = res.data;
        }
    } catch (err) {
        if (err.response) {
            showError(err.response.message)
        }
    }
}

async function getListAndSetData() {
    await getGrnLandedCosting(route.query.landedCosting);
}

onMounted(async () => {
    await getAccountPayable();
    await getListAndSetData();

})
</script>
